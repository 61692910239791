import React from 'react';
import styled from './index.module.css';
import ImageFeatProductCircle from '../../images/imageComponents/ImageFeatProductCircle';
import ImageFeatProductRectangle from '../../images/imageComponents/ImageFeatProductRectangle';
import ImageFeatProductLine from '../../images/imageComponents/ImageFeatProductLine';
import { Link } from 'gatsby';
import { cloudiTransform } from '../cloudinaryUtils';

const FeaturedProduct = ({ edge, idx }) => {
  const PositionInRoll = (idx % 4) + 1;

  return (
    <div className={styled.wrapper}>
      <Link className={styled.linkFeaturedProduct} to={`/products/${edge.node.fields.slug}`}>
        <div className={styled.featuredProductGraphicComposition}>
          <img
            className={styled[`featuredProductImage${PositionInRoll}`]}
            loading="lazy"
            src={cloudiTransform(edge.node.frontmatter.image, 'w_400,h_400/')}
            alt={edge.node.frontmatter.title}
          />
          {PositionInRoll === 1 ? (
            <>
              <ImageFeatProductCircle styled={styled.featuredProduct1Circle} />
              <ImageFeatProductLine styled={styled.featuredProduct1Line} />
            </>
          ) : PositionInRoll === 2 ? (
            <ImageFeatProductRectangle styled={styled.featuredProduct2Rectangle} />
          ) : PositionInRoll === 3 ? (
            <div className={styled.featuredProduct3Circle}>
              <ImageFeatProductCircle />
            </div>
          ) : PositionInRoll === 4 ? (
            <div className={styled.featuredProduct4Line}>
              <ImageFeatProductLine />
            </div>
          ) : null}
        </div>
        <h2>{edge.node.frontmatter.title}</h2>
        <div className={styled.textBlock} dangerouslySetInnerHTML={{ __html: edge.node.html }} />
      </Link>
    </div>
  );
};

export default FeaturedProduct;
