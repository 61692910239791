import React from 'react';
import styled from './index.module.css';
import StylishThumbs from '../../../components/StylishThumbs';
import { useFeaturedProductsRoll } from '../../../hooks/featuredProductRollQuery';

const IndexPage = () => {
  const { edges } = useFeaturedProductsRoll();

  return (
    <div className={styled.wrapper}>
      {edges.map((edge, idx) => (
        <div className={styled[`indexfeatProduct${idx + 1}`]} key={idx}>
          <StylishThumbs edge={edge} idx={idx} />
        </div>
      ))}
    </div>
  );
};

export default IndexPage;
